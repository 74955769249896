<i18n>
{
  "de": {
    "lifespanHeading": "Lebensdauer",
    "nameTitle": "Komponente",
    "lifespanTitle": "Lebensdauer",
    "yearsUnit": "Jahre",
    "components": {
      "basement": "Keller / Kellerdecke",
      "heating": "Wärmeerzeuger",
      "heating_distribution": "Wärmeverteilung",
      "pv": "Photovoltaikanlage",
      "roof_flat": "Flachdach",
      "roof_gable": "Schrägdach",
      "walls": "Fassade",
      "windows": "Fenster"
    }
  }
}
</i18n>

<template>
  <edit-container :bind-form-state="formState" @container="onFormEvent" class="edit-lifespans">
    <h2>{{ $t('lifespanHeading') }}</h2>
    <detail-list class="lifespans-table">
      <template #header>
        <span>{{ $t('nameTitle') }}</span>
        <span>{{ $t('lifespanTitle') }}</span>
      </template>
      <li v-for="(lifespan, componentKey) in model" :key="componentKey">
        <div>
          {{ $t(`components.${componentKey}`) }}
        </div>
        <div>
          <numeric-input v-model="model[componentKey]" :edit="isEditing" optional :units="$t('yearsUnit')" />
        </div>
      </li>
    </detail-list>
  </edit-container>
</template>
<script>
import { mapActions } from 'vuex'

import ApiFormMixin from '@/components/shared/forms/ApiFormMixin'
import EditContainer from '@/components/shared/forms/EditContainer'

import DetailList from '@/components/shared/lists/DetailList'
import NumericInput from '@/components/shared/forms/NumericInput'

export default {
  mixins: [
    ApiFormMixin
    /* ApiFormMixin:
      - Requires implementation of the following methods: resetModel, saveHandler, isValid (optional)
      - Provides: model, isValid, isEditing, formState, onFormEvent, onValidation */
  ],
  components: {
    DetailList,
    NumericInput,
    EditContainer
  },
  props: {
    portfolio: {
      type: Object,
      required: true
    }
  },
  methods: {
    ...mapActions({
      updateParams: 'portfolio/updateParams'
    }),
    resetModel () {
      this.model = this.portfolio.lifespans
    },
    async saveHandler () {
      await this.updateParams({
        id: this.portfolio.id,
        params: {
          'lifespans': this.model
        }
      })
    }
  }
}
</script>
